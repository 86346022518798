import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideDigits'
})
export class HideDigitsPipe implements PipeTransform {

  transform(str?: string, lastdigitstoHide?: number, showHide?: boolean): any {
    let lastString = str.substring((str.length - (lastdigitstoHide + 1)), (str.length - 1));
    let startString = str.substring(0, (str.length - (lastdigitstoHide + 1)));
    let stars = Array(startString.length).join("*")
    if (showHide == true) {
      return stars + lastString;
    }
    else if (showHide == false)
      return str;
  }

}
//usage
//| hideDigits:3:false
