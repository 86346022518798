import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/services/http-service';
import { Subscription } from 'rxjs';
import { Globals } from 'src/app/Globals';
import { Router } from '@angular/router';
import {SocketIoConfig} from "ngx-socket-io";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {

  private globals = Globals;
  httpSub$: Subscription = null;


  constructor(private httpService: HttpService, private router: Router) { }

  ngOnInit() {
    this.httpSub$ = this.httpService.getRequest(this.globals.urls.logout)
      .subscribe(
        res => {
          if (res.success === 1) {
            localStorage.removeItem('darkCarz-admin-data');
            this.router.navigate(['/login']);
            this.httpService.showSuccess(res['message'], 'LogOut');
          } else {
            this.httpService.showError(res['message'], 'LogOut');
            this.router.navigate(['/login']);
          }
        },
        err => this.httpService.showError(err['message']),
      );
  }

  ngOnDestroy() {
    if(this.httpSub$) this.httpSub$.unsubscribe();
  }

}
