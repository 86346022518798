import { environment } from '../environments/environment';
import { NgForm } from '@angular/forms';
import { b } from '@angular/core/src/render3';

const baseUrl = environment.baseUrl;

export const Globals = {
  appName: 'darkCarz',
  defaultImage: 'https://ondemandstartup.s3.us-west-2.amazonaws.com/profileImages/1000X1000/1540906183439-icon_default_image.png',
  defaultCurrency: '$',

  regex: {
    email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    ssn: /\d{3}-\d{2}-\d{4}/,
    date: /\d{2}\/\d{2}\/\d{4}/,

    // password must be 8 digits and contain 1 uppercase and lowercase letters, 1 special character, 1 numeric
    passwordStrength: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    passRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
  },

  // to be used with the TextMask Module from angular2-text-mask package
  masks: {
    phone: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    ssn: [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    zip: [/[0-9]/, /\d/, /\d/, /\d/, /\d/],
  },

  urls: {
    login: baseUrl + 'admin/login',
    send2Fa: baseUrl + '/admin/send/verification-code',
    logout: baseUrl + 'admin/logout',
    uploadImage: baseUrl + 'upload/vehicle-image',
    uploadDocument: baseUrl + 'upload/commercial-insurance-document',
    getPrfixes: baseUrl + '/country/codes',
    getAllLanguages: baseUrl + '/active-languages',
    getLanguageLevels: baseUrl + '/language-level',
    getCSVDetails: baseUrl + '/user-detail-to-csv',
    getCSVJob: baseUrl + '/job-to-csv',
    gdprLoging: baseUrl + '/admin/view-info',
    getJobChatHistory: baseUrl + '/job-chathistory-to-csv',
    deleteUserCompletely: baseUrl + '/admin/mark/delete',
    GetTightenUp: {
      activeBlockUser: baseUrl + '/admin/block-user-account',
      workers: {
        // tslint:disable-next-line:max-line-length
        // fetchSPList: baseUrl + '/admin/fetch-sp-list?status=:status&jobTypeId=:jobTypeId&search_text=:search_text&limit=:limit&offset=:offset&approved=:approved',
        fetchJobTypes: baseUrl + '/admin/fetch-services-list',
        fetchProfileData: baseUrl + '/admin/fetch-sp-profile-data?workerId=:workerId',
        fetchSpDetails: baseUrl + '/admin/fetch-sp-details?workerId=:workerId',
        // tslint:disable-next-line:max-line-length
        fetchNewSpUser: baseUrl + '/admin/fetch-new-sp-list?status=:status&jobTypeId=:jobTypeId&search_text=:search_text&limit=:limit&offset=:offset',
        // tslint:disable-next-line:max-line-length
        fetchSpJobList: baseUrl + '/admin/fetch-sp-jobs?workerId=:workerId&search_text=:search_text&status=:status&limit=:limit&offset=:offset',
        fetchSpJobDetails: baseUrl + '/admin/fetch-sp-job-detail/:jobId',
        approveWorker: baseUrl + '/admin/approve-worker-account',
        rejectWorker: baseUrl + '/admin/reject-worker-account',
        getNewCount: baseUrl + '/admin/get/new-worker-count',
        editWorker: {
          getProfile: baseUrl + '/admin/get-worker-profile?workerId=:workerId',
          updateWorkerProfile: baseUrl + '/admin/update-worker-profile?workerId=:workerId',
          getWorkerServices: baseUrl + '/admin/get-worker-services?workerId=:workerId',
          updateWorkerServices: baseUrl + '/admin/update-worker-service',
          getIdDoc: baseUrl + '/admin/get-worker-identitydoc?workerId=:workerId',
          updateIdDoc: baseUrl + '/admin/update-worker-identitydoc',
          getProfDoc: baseUrl + '/admin/get-worker-professional-doc?workerId=:workerId',
          updateProfDoc: baseUrl + '/admin/update-worker-professional-doc',
          getBankDetails: baseUrl + '/admin/get-worker-bank-info/:workerId',
          updateBankDetails: baseUrl + '/admin/update-worker-bank-info/:workerId',
          getUserLanguageDetails: baseUrl + '/admin/get-worker-languages?workerId=:workerId',
          updateUserLanguages: baseUrl + '/admin/update-worker-language',
        }
      },
      company: {
        // tslint:disable-next-line:max-line-length
        fetchCompanyList: baseUrl + '/admin/fetch-company-list?jobTypeId=:jobTypeId&search_text=:search_text&limit=:limit&offset=:offset&approved=:status',
        fetchCompanyDetails: baseUrl + '/admin/fetch-company-details?companyId=:companyID',
        // tslint:disable-next-line:max-line-length
        fetchCompanyWorkerList: baseUrl + '/admin/fetch-company-worker-list/companyId/:companyID?search_text=:search_text&status=:status&limit=:limit&offset=:offset&approved=0'
      },
      users: {
        // tslint:disable-next-line:max-line-length
        fetchUsersList: baseUrl + '/admin/fetch-users-list?status=:status&jobTypeId=:jobTypeId&search_text=:search_text&limit=:limit&offset=:offset',
        // tslint:disable-next-line:max-line-length
        fetchUsersJobLists: baseUrl + '/admin/fetch-user-jobs-list?userId=:userId&search_text=:search_text&limit=:limit&offset=:offset&status=:status',
        fetchUserJobDetails: baseUrl + '/admin/fetch-user-job-detail?jobId=:jobId',
      },
      jobs: {
        fetchJobsList: baseUrl + '/admin/fetch-jobs-list?status=:status&search_text=:search_text&limit=:limit&offset=:offset',

      },
      notification: {
        getNotifications: baseUrl + '/admin/general/notifications/:userType',
        postNotifications: baseUrl + '/admin/update/general-notifications',
      },
      RolesSecurity: {
        getRoles: baseUrl + 'admin/sub-admins/list?offset=:offset&limit:limit&searchFilter=:searchFilter',
        AddRoles: baseUrl + 'admin/sub-admin',
        getSingleRole: baseUrl + 'admin/sub-admin/:subAdminId',
        updateRoles: baseUrl + 'admin/sub-admin/:subAdminId',
      },
      Referrals: {
        getReferralsList: baseUrl + '/admin/:userType/referral-list?search_text=:search_text&limit=:limit&offset=:offset',
        getReferrals: baseUrl + '/referral-listing?userType=:userType&limit=:limit&offset=:offset&search_text=:search_text',
        getSettingsData: baseUrl + '/admin/fetch/referral',
        setSettingsData: baseUrl + '/admin/update/referral',
      },
      Loging: {
        getLogs: baseUrl + '/activity/get/logs',
      }
    },
    customers: {
      fetchCustomers: baseUrl + 'admin/users?listType=:listType&searchText=:filter&limit=:limit&offset=:offset',
      fetchCustomerDetails: baseUrl + 'admin/user/:id',
      fechCustomerJObs: baseUrl + 'admin/user/:id/jobs?listType=:listType&searchText=:filter&limit=:limit&offset=:offset',
      updateCustomerProfile: baseUrl + 'admin/user/:id/profile',
      updateCustomer: baseUrl + 'admin/user/:id/status',
    },
    merchant: {
      fetchMerchants: baseUrl + 'admin/merchants?limit=:limit&offset=:offset&searchFilter=:companyName&listType=:isBlocked',
      fetchMerchantDetails: baseUrl + 'admin/merchant/:id',
      updateMerchant: baseUrl + 'admin/merchant/:id',
      fetchMerchantDrivers: baseUrl + 'admin/merchant/:id/drivers?limit=:limit&offset=:offset&searchFilter=:filter',
      fetchMerchantRides: baseUrl + 'admin/merchant/:id/jobs?listType=:listType&offset=:offset&limit=:limit&searchFilter=:filter',
      updateMerchantProfile: baseUrl + 'admin/merchant/:id/profile',
      updateSalesPersonCommissionStatus: baseUrl + 'admin/merchant/:id/update',
    },
    drivers: {
      fetchDriverRides: baseUrl + 'admin/map/jobs?latitude=:latitude&longitude=:longitude&offset=:offset&limit=:limit',
      fetchDriverHistory: baseUrl + 'admin/driver/:id/jobs?listType=:listType&limit=:limit&offset=:offset',
      fetchDriverDetails: baseUrl + 'admin/driver/:id',
      updateDriverStatus: baseUrl + 'admin/driver/:id/status',
      fetchDrivers: baseUrl + 'admin/drivers?searchText=:filter&limit=:limit&offset=:offset',
    },
    vehicles: {
      fetchVehiclesRequests: baseUrl + 'admin/vehicle/requests?listType=:listtype&limit=:limit&offset=:offset',
      fetchVehicleDetails: baseUrl + 'admin/vehicle/:id',
      updateVehicle: baseUrl + 'admin/vehicle/:id',
      fetchMerchantVehicles: baseUrl + 'admin/merchant/:id/vehicles?limit=:limit&offset=:offset',
      color: {
        fetchVehicleColors: baseUrl + 'admin/vehicle-colors?limit=:limit&offset=:offset&searchFilter=:filter',
        colorUrl: baseUrl + 'admin/vehicle-color',
        deleteColorUrl: baseUrl + 'admin/vehicle-color/:id'
        // addColor: baseUrl + 'admin/vehicle-color'
      },
      manufacturer: {
        fetchVehicleManufacturer: baseUrl + 'admin/vehicle-manufacturers?limit=:limit&offset=:offset&searchFilter=:filter',
        manufacturerUrl: baseUrl + 'admin/vehicle-manufacturer',
        deleteManufacturerUrl: baseUrl + 'admin/vehicle-manufacturer/:id'
        // addColor: baseUrl + 'admin/vehicle-color'
      },
      category: {
        fetchVehicleCategories: baseUrl + 'admin/vehicle-categories?limit=:limit&offset=:offset&searchFilter=:filter',
        categoryUrl: baseUrl + 'admin/vehicle-category',
        deleteCategoryUrl: baseUrl + 'admin/vehicle-category/:id',
        // addColor: baseUrl + 'admin/vehicle-color'
      },
      type: {
        fetchVehicleTypes: baseUrl + 'admin/vehicle-category/:id?limit=:limit&offset=:offset&searchFilter=:filter',
        typeUrl: baseUrl + 'admin/vehicle-type',
        fethDetails: baseUrl + 'admin/vehicle-type/:id',
        deleteTypeUrl: baseUrl + 'admin/vehicle-type/:id',
        updateVehicleType: baseUrl + 'admin/vehicle-type'
        // addColor: baseUrl + 'admin/vehicle-color'
      },

    },
    vehiclepromos: {
      fetchPromoCodes: baseUrl + 'admin/promo-codes?limit=:limit&offset=:offset',
      fetchVehiclesForPromo: baseUrl + 'admin/vehicle-types',
      addPromoCode: baseUrl + 'admin/promo-code',
      fetchPromoDetails: baseUrl + 'admin/promo-code/:id',
      updatePromoDetails: baseUrl + 'admin/promo-code/:id',
      deletePromoCode: baseUrl + 'admin/promo-code/:id'
    },
    sideOPtions: {
      fetchOptionsList: baseUrl + 'admin/side-options?limit=:limit&offset=:offset',
      addSideOption: baseUrl + 'admin/side-option',
      deleteSideOption: baseUrl + 'admin/side-option/:id',
      fetchSideOption: baseUrl + 'admin/side-option/:id',
      fetchSideOptionCategories: baseUrl + 'admin/side-options/categories?limit=100&offset=0',
    },
    rides: {
      fetchJobDetails: baseUrl + 'admin/job/:id',
      fetchAllJobs: baseUrl + 'admin/jobs?limit=:limit&offset=:offset&listType=:listType&searchFilter=:searchFilter',
    },
    authentication: {
      getCurrentUser: baseUrl + 'admin/profile',
      logout: baseUrl + '/admin/logout'
    },

    dashBoard: {
      analytics: baseUrl + 'admin/dashboard?month=:month&year=:year'
    },

    notification: {
      getNotifications: baseUrl + 'admin/notifications?limit=:limit&offset=:offset'
    },

    post: {
      headerNotifcations: baseUrl + '/admin/fetch/notification',
    },

    map: {
      activeJobs: baseUrl + '/admin/fetch-active-sp-list',
      inactiveJobs: baseUrl + '/admin/fetch-inactive-sp-list',
      count: baseUrl + '/admin/fetch-sp-count',
      cancelJob: baseUrl + '/admin/cancel-job',
      finishJob: baseUrl + '/admin/'
    },

    job: {
      detail: baseUrl + '/admin/fetch-sp-job-detail'
    },

    settings: {

      profile: {
        update: baseUrl + '/admin/updateProfile',
        get: baseUrl + 'admin/profile',
        updatePassword: baseUrl + 'admin/change/password'
      },

      commission: {
        get: baseUrl + '/admin/get/commission',
        update: baseUrl + '/admin/update/commission',
      },

      services: {
        list: baseUrl + '/admin/fetch-services-list',
        get: baseUrl + '/admin/get-service',
        add: baseUrl + '/admin/add-service',
        update: baseUrl + '/admin/update-service',
        delete: baseUrl + '/admin/delete-service?type=:type',
      },
      subService: {
        fetchList: baseUrl + '/admin/fetch-sub-services-list/:serviceID',
        fetchDetail: baseUrl + '/admin/fetch-sub-services-types-list/:subID',
        updateSubServicetype: baseUrl + '/admin/update-sub-service-type',
        updateSubService: baseUrl + '/admin/update-sub-service',
        addSubServicetype: baseUrl + '/admin/add-sub-service-roles',
        addSubService: baseUrl + '/admin/add-sub-service'
      },


      promocodes: {
        list: baseUrl + '/admin/get/promocodes',
        add: baseUrl + '/admin/add/promocode',
        update: baseUrl + '/admin/promocode/promoCodeStatusUpdate',
        fetch: baseUrl + '/admin/get/promocode',
        delete: baseUrl + '/admin/delete/promocode'
      },

      reasons: {
        add: baseUrl + '/admin/add/reasons',
        get: baseUrl + '/admin/get/reasons',
        update: baseUrl + '/admin/update/reason',
      },

      payouts: {
        list: baseUrl + '/admin/get-worker-earnings',
        get: baseUrl + '/admin/get-worker-earning-detail',
        pay: baseUrl + '/admin/payout'
      },

      serviceCharges: {
        get: baseUrl + '/admin/get/setting',
        edit: baseUrl + '/admin/update/setting'
      },
      configurations: {
        get: baseUrl + 'admin/configurations'
      },
      termsCondition: {
        list: baseUrl + '/admin/get/termsconditions',
        add: baseUrl + '/admin/add/termscondition',
        fetch: baseUrl + '/admin/get/termscondition'
      },
      privacyPolicy: {
        list: baseUrl + '/admin/get/privacypolicies',
        add: baseUrl + '/admin/add/privacypolicy',
        fetch: baseUrl + '/admin/get/privacypolicy'
      }
    },
    salesPerson: {
      fetchSalesPerson: baseUrl + 'admin/sales-person?searchText=:filter&limit=:limit&offset=:offset',
      fetchDetails: baseUrl + 'admin/sales-person/:id',
      fetchMerchants: baseUrl + 'sales-merchant/:id?searchFilter=:filter&limit=:limit&offset=:offset',
      updateSalesPersonCommissionStatus: baseUrl + 'admin/sales-person/:id/update'
    },


  }
};

export const checkIfOnlySpaces = (form: NgForm, control: string) => {
  // value exists but only spaces
  if (form.controls[control].value && form.controls[control].value.trim().length === 0) {

    form.controls[control].setValue('');
    return form.controls[control].setErrors({ required: true });

  } else if (form.controls[control].value) {
    // trim the value
    form.controls[control].setValue(form.controls[control].value.trim());
  } else {
    form.controls[control].setValue('');
  }

};
